<template>
  <div>
    <DepartmentLiquidation
      :title="'FUND MONITORING'"
    />
  </div>
</template>
<script>
  import DepartmentLiquidation
    from '@/views/view_interface/monitoring/fund_monitoring/DepartmentFundMonitoring'

  export default {
    components: {
      DepartmentLiquidation,
    },
  }
</script>
